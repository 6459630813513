<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: FeedConsumptionCard.vue
Description:This file gives the information of feed consumption according to the selected Date range
-->
<template>
  <er-card
    class="feedconsumption-count-card"
    v-loading="computedLoading"
    element-loading-background="white"
    ref="feed-consumption-count-card"
  >
    <layout-toolbar
      v-show="!computedLoading"
      slot="header"
      justify="start"
      :class="`feedconsumption-card-count__header ${selectedFeedPeriod}`"
    >
      <img class="card-icon" src="@/assets/dashboard/feedconsumption.svg" />
      <span class="card-title">{{
        $t("Comn_feed_consumption")
      }}</span>
      <div class="filler"></div>
      <span
        tag="span"
        :class="`feed-stat ${feedPeriodList[selectedFeedPeriod].css_class}`"
      >
        <p tag="p" class="feed-stat__value">
          <el-popover
            v-model="popoverClose"
            popper-class="fcc-compare-feed-popper"
          >
            <el-row class="bottom-margin small-text font-primary-color">{{
              $t("compare_total_dispensed")
            }}</el-row>
            <er-date-range-filter2
              :value="dateRanges"
              :displayFormat="upm__getFormatDateString"
              :timeZoneString="upm__getFormatTimeString"
              @change="handleDateRangeQuery"
              @cancel="handleCancelDateRangeQuery"
            >
              <template slot="range_1_value">
                <p
                  class="range-value-width"
                  v-upm__units.digitPrecision.truncateThousandStr="
                    getDispenseValueInitial
                  "
                ></p>
              </template>
              <template slot="range_2_value">
                <p
                  class="range-value-width"
                  v-upm__units.digitPrecision.truncateThousandStr="
                    getDispenseValueSecondary
                  "
                ></p>
              </template>
            </er-date-range-filter2>
            <el-col slot="reference">
              <el-tag
                size="small"
                :type="getHourlyFeedGrowthStat >= 0 ? 'primary' : 'danger'"
              >
                <i class="el-icon-loading" v-show="loadingForPercentage"></i>
                <el-row
                  type="flex"
                  v-show="!loadingForPercentage"
                  align="middle filter-popover-trigger"
                >
                  <p type="text">{{ getHourlyFeedGrowthStat }}%</p>
                  <el-divider direction="vertical"></el-divider>
                  <i class="el-icon-date"></i>
                </el-row>
              </el-tag>
            </el-col>
          </el-popover>
        </p>
      </span>

      <el-button-group class="feed-consumption-filter-icon">
        <el-button size="mini" type="text" @click="handleFilterClick">
          <span
          class="material-icons-outlined"
          :title="$t('Comn_Filters')"
        >
          filter_alt
        </span>
        </el-button>
      </el-button-group>
    </layout-toolbar>
    <el-row v-show="!filterClicked && !computedLoading">
      <feedConsumptionHighChart
        :feedAreaSwitch="feedingAreaFilterValue"
        :selectedFeedPeriod="feedingFrequencyFilterValue"
        :feedMode="feedingModeFilterValue"
      ></feedConsumptionHighChart>
    </el-row>
    <FiltersModal
      :title="$t('Comn_feed_consumption')"
      :showFiltersModal="filterClicked"
      :loading="filterLoading"
      @save="() => handleSaveFilterData(feedAreaSwitch, selectedFeedPeriod, selectedMode)"
      @close="handleCloseDialog"
      @reset="() => handleSaveFilterData(...Object.values(DEFAULT_FILTERS))"
    >
      <!-- <template slot="leftPanel">
        <feedConsumptionHighChart
          :feedAreaSwitch="computedFeedAreaSwitch"
          :selectedFeedPeriod="computedSelectedFeedPeriod"
          v-loading="computedLoading"
        ></feedConsumptionHighChart>
      </template> -->
      <template slot="rightPanel">
        <el-form>
          <div class="feedConsumption-filter-card">
            <el-form-item :label="$t('Feeding_Area')">
              <el-switch
                v-model="feedAreaSwitch"
                active-color="#0A2463"
                :active-text="getLabelByUserPreferences"
                @change="handleChangeSwitch"
              >
              </el-switch>
            </el-form-item>
          </div>
          <div class="feedConsumption-filter-card">
            <el-form-item :label="$t('Feeding_Frequency')">
              <er-select
                size="mini"
                v-model="selectedFeedPeriod"
                collapse-tags
                :showSelectAll="false"
                @change="handleChangeInSelectedPonds"
                :placeholder="$t('Comn_select')"
              >
                <el-option
                  v-for="(obj, type) in feedPeriodList"
                  :key="type"
                  :label="$t(obj.title)"
                  :value="type"
                ></el-option>
              </er-select>
            </el-form-item>
          </div>
          <div class="feedConsumption-filter-card">
            <el-form-item :label="$t('mode')">
              <er-select
                size="mini"
                v-model="selectedMode"
                collapse-tags
                :showSelectAll="false"
                @change="handleChangeInSelectedPonds"
                :placeholder="$t('Comn_select')"
              >
                <el-option
                  v-for="obj in feedModesList"
                  :key="obj.mode"
                  :label="$t(obj.lang_key)"
                  :value="obj.mode"
                ></el-option>
              </er-select>
            </el-form-item>
          </div>
        </el-form>
      </template>
    </FiltersModal>
  </er-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import feedConsumptionHighChart from "../feedConsumptionChart.vue";
import FiltersModal from "./FiltersModal.vue";
import { FEED_CONSUMPTION_FILTERS as DEFAULT_FILTERS } from "../utils/defaultDashboardFilters";
export default {
  name: "FeedConsumptionCard",
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    resizeContainerMixin,
    userPreferenceMixin,
    datesHandlerMixin,
  ],
  components: {
    feedConsumptionHighChart,
    FiltersModal,
  },
  props: ["isLoading"],
  data: function () {
    return {
      query: {
        location_id: null,
      },
      feedAreaSwitch: false,
      selectedFeedPeriod: "daily",
      selectedMode: "ALL",
      feedModesList: [
        { mode: "ALL", lang_key: "Comn_all" },
        { mode: "BASIC", lang_key: "PM_sch_mode4" },
        { mode: "SCHEDULE", lang_key: "Comn_schedule" },
        { mode: "AUTOMATIC", lang_key: "PM_automatic" },
      ],
      feedPeriodList: {
        daily: {
          css_class: "feed-stat--daily",
          title: "daily",
          color: "#155DBE",
          date_units: "days",
          isSameComparator: "isSameDay",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_bar_color: "#155DBE",
            light_color: "#cbe6f6",
            dark_color: "#2D9CDB",
          },
        },
        weekly: {
          css_class: "feed-stat--weekly",
          title: "Comn_weekly",
          color: "#D39F00",
          date_units: "weeks",
          isSameComparator: "isSameISOWeek",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_bar_color: "#D39F00",
            light_color: "#fcefc9",
            dark_color: "#F2C94C",
          },
        },
        monthly: {
          // period_type: "monthly",
          css_class: "feed-stat--monthly",
          title: "Comn_monthly",
          color: "#6FCF97",
          date_units: "months",
          isSameComparator: "isSameMonth",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_bar_color: "#3e9f66",
            light_color: "#dbf3e5",
            dark_color: "#6FCF97",
          },
        },
      },
      loading: false,
      popoverClose: false,
      dateRanges: [
        [new Date(), new Date()],
        [new Date(), new Date()],
      ],
      loadingForPercentage: false,
      dateFormat: "YYYY-MM-DD",
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y",
      },
      filterClicked: false,
      filterLoading: false,
      DEFAULT_FILTERS
    };
  },
  watch: {
    async isLoading(newValue, oldValue) {
      if (!newValue) {
        await this.handleDateRangeQuery(this.getDefaultDateRanges);
      }
    },
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getPreferredUnits: "getPreferredUnits",
      getUserId: "getUserId",
    }),
    ...mapGetters("dashboard", {
      getFeedConsumptionData: "getFeedConsumptionData",
      getFeedConsumptionGrowth: "getFeedConsumptionGrowth",
      getDashboardFilters: "getDashboardFilters",
      getIsFeedConsumptionFiltersUpdated: "getIsFeedConsumptionFiltersUpdated",
    }),
    feedConsumptionFilters() {
      return this.getDashboardFilters.FEED_CONSUMPTION;
    },
    feedingAreaFilterValue() {
      return this.feedConsumptionFilters.feeding_area;
    },
    feedingFrequencyFilterValue() {
      return this.feedConsumptionFilters.feeding_frequency;
    },
    feedingModeFilterValue() {
      return this.feedConsumptionFilters.feeding_mode
    },
    getDispenseValueInitial() {
      const pondSizeAndUnits = {
        value: this.getRangeFeedDispensedValues[0],
        previousunits: this.upm__backendUnits.feed_units,
        units: this.upm__feedUnits,
        modifiersParams: {
          digitPrecision: [0],
          truncateThousandStr: [0],
          formatEmpty: ["-"],
        },
      };
      return pondSizeAndUnits;
    },
    getDispenseValueSecondary() {
      const pondSizeAndUnits = {
        value: this.getRangeFeedDispensedValues[1],
        previousunits: this.upm__backendUnits.feed_units,
        units: this.upm__feedUnits,
        modifiersParams: {
          digitPrecision: [0],
          truncateThousandStr: [0],
          formatEmpty: ["-"],
        },
      };
      return pondSizeAndUnits;
    },
    getLabelByUserPreferences() {
      const areaToKeyMap = {
        ac: "Kg/Ac",
        ha: "Kg/Ha",
      };
      return areaToKeyMap[this.upm__getPondSizeUnits];
    },
    computedLoading() {
      return this.loading || this.isLoading;
    },
    getScreenResolution() {
      return window.innerWidth;
    },
    getRangeFeedDispensedValues() {
      if (!this.getFeedConsumptionGrowth) {
        return [];
      }
      return [
        this.getFeedConsumptionGrowth.range1,
        this.getFeedConsumptionGrowth.range2,
      ].map((x) => x && x.feed_values.total_dispensed_feed);
    },
    getTrendImg() {
      if (this.getHourlyFeedGrowthStat > 0) {
        return require(`@/assets/dashboard/upTrend.svg`);
      } else if (this.getHourlyFeedGrowthStat < 0) {
        return require(`@/assets/dashboard/downTrend.svg`);
      }
      return "";
    },
    getDefaultDateRanges() {
      const selectedPeriod = this.feedPeriodList[this.selectedFeedPeriod];
      const dateUnits = selectedPeriod.date_units;
      const format = "yyyy-MM-dd";
      const unitsToStartOf = {
        days: "startOfDay",
        weeks: { fn: "startOfWeek", params: { weekStartsOn: 1 } },
        months: "startOfMonth",
      };
      const unitsToEndOf = {
        days: "endOfDay",
        weeks: { fn: "endOfWeek", params: { weekStartsOn: 1 } },
        months: "endOfMonth",
      };
      const dateInstance = () =>
        this.dhm__dateUtilsLib.endOfDay(
          this.dhm__dateUtilsLib.toDate(this.dhm__getTodayInUserTZ)
        );
      const endDayGenerator = (units) => [
        this.dhm__dateUtilsLib.subtract(dateInstance(), { [units]: 1 }),
        this.dhm__dateUtilsLib.subtract(dateInstance(), { [units]: 2 }),
      ];
      const formatFn = (date, format) =>
        this.dhm__dateUtilsLib.formatDate(date, format);
      const startOf = (date, units) => {
        if (unitsToStartOf[units].params) {
          return this.dhm__dateUtilsLib[unitsToStartOf[units].fn](
            date,
            unitsToStartOf[units].params
          );
        }
        return this.dhm__dateUtilsLib[unitsToStartOf[units]](date);
      };
      const endOf = (date, units) => {
        if (unitsToEndOf[units].params) {
          return this.dhm__dateUtilsLib[unitsToEndOf[units].fn](
            date,
            unitsToStartOf[units].params
          );
        }
        return this.dhm__dateUtilsLib[unitsToEndOf[units]](date);
      };
      const rangeGenerator = (units) => {
        const endDays = endDayGenerator(units);
        return endDays.map((x) => [
          formatFn(startOf(x, units), format),
          formatFn(endOf(x, units), format),
        ]);
      };
      return rangeGenerator(dateUnits);
    },
    getHourlyFeedGrowthStat() {
      if (!this.getFeedConsumptionGrowth) {
        return 0;
      }
      const { range1, range2 } = this.getFeedConsumptionGrowth;
      if (!range1 || !range2) return 0;
      const totalDispensed1 = range1.feed_values.total_dispensed_feed;
      const totalDispensed2 = range2.feed_values.total_dispensed_feed;
      if (!totalDispensed2) return 0;
      // Considering yesterdays as orginal
      if (totalDispensed1 > 0) {
        const change = totalDispensed1 - totalDispensed2;
        return Math.round((change / totalDispensed2) * 100);
      } else {
        return 0;
      }
    },
  },
  async mounted() {
    this.rcm__registerResizeObserver(
      "feed-consumption-count-card",
      this.rcm__debounce(100, () => {
        if (this.$refs.highcharts) {
          this.$refs.highcharts.chart.reflow();
        }
      })
    );
  },
  methods: {
    ...mapActions("dashboard", {
      fetchFeedConsumptionData: "fetchFeedConsumptionData",
      fetchHourlyFeedGrowthStat: "fetchHourlyFeedGrowthStat",
      changeFeedPeriod: "changeFeedPeriod",
      fetchFeedConsumptionGrowth: "fetchFeedConsumptionGrowth",
      updateDashboardFilters: "updateDashboardFilters",
      fetchDashboardFilters: "fetchDashboardFilters",
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator",
    }),
    async handleSaveFilterData(feedAreaSwitch, selectedFeedPeriod, selectedMode) {
      try {
        this.filterLoading = true;
        const payload = {
          preferences: [
            {
              user_id: this.getUserId,
              widget_name: "FEED_CONSUMPTION",
              filter_name: "feeding_area",
              filter_type: "BOOLEAN",
              is_default: false,
              values: [feedAreaSwitch],
            },
            {
              user_id: this.getUserId,
              widget_name: "FEED_CONSUMPTION",
              filter_name: "feeding_frequency",
              filter_type: "STRING",
              is_default: false,
              values: [selectedFeedPeriod],
            },
            {
              user_id: this.getUserId,
              widget_name: "FEED_CONSUMPTION",
              filter_name: "feeding_mode",
              filter_type: "STRING",
              is_default: false,
              values: [selectedMode],
            },
          ],
        };
        await this.updateDashboardFilters({
          widgetName: "FEED_CONSUMPTION",
          params: payload,
        });
        this.filterClicked = false;
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Filters_success_msg"),
          duration: 5000,
          type: "success",
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.filterLoading = false;
      }
    },
    handleCloseDialog() {
      this.filterClicked = false;
      // this.feedAreaSwitch = this.feedConsumptionFilters.feeding_area;
      // this.selectedMode = this.feedConsumptionFilters.feeding_mode;
      // this.selectedFeedPeriod = this.feedConsumptionFilters.feeding_frequency;
    },
    handleFilterClick() {
      this.feedAreaSwitch = this.feedConsumptionFilters.feeding_area;
      this.selectedMode = this.feedConsumptionFilters.feeding_mode;
      this.selectedFeedPeriod = this.feedConsumptionFilters.feeding_frequency;
      this.filterClicked = true;
    },
    async handleChangeInSelectedPonds() {
      this.mixPanelEventGenerator({
        eventName: "Dashboard - Feed Consumption - Dropdown",
      });
    },
    handleChangeSwitch() {
      this.mixPanelEventGenerator({
        eventName: "Dashboard - Feed Consumption - Kg/Ac Toggle",
      });
    },
    async handleDateRangeQuery(value) {
      this.closePopOver();
      try {
        const dateRanges = value || this.getDefaultDateRanges;
        this.dateRanges = dateRanges;
        this.loadingForPercentage = true;
        const queryFeedConsumptionGrowth = {
          location_id: this.getCurrUserLocation && this.getCurrUserLocation._id,
          range1: {
            start_date: dateRanges[0][0],
            end_date: dateRanges[0][1],
          },
          range2: {
            start_date: dateRanges[1][0],
            end_date: dateRanges[1][1],
          },
        };
        await this.fetchFeedConsumptionGrowth(queryFeedConsumptionGrowth);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loadingForPercentage = false;
      }
    },
    closePopOver() {
      this.popoverClose = false;
    },
    handleCancelDateRangeQuery(value) {
      this.closePopOver();
    },
    getDispFeedPercent(dispFeed, totalFeed) {
      return Math.round((dispFeed * 100) / totalFeed);
    },
  },
};
</script>

<style lang="scss">
.feedConsumption-filter-card {
  // padding: 12px;
  .el-form-item {
    margin-bottom: 6px;
  }

  .el-form-item .el-form-item__label {
    width: 200px;
  }
}

.feed-consumption-filter-icon {
  display: flex;
  justify-content: end;

  .el-button--text {
    color: unset;
  }
}

.range-value-width {
  width: 37px;
  display: flex;
  justify-content: center;
}

.el-range-separator {
  width: auto !important;
}

@mixin component-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}

.el-switch__label {
  span {
    color: #0a2463 !important;
  }
}

.feedconsumption-count-card {
  display: flex;
  flex-direction: column;

  .filters-modal {
    @include responsiveProperty(--dialog-width, 45%, 35%, 25%);
  }
  // 5px 10px 10px 10px;
  .page_loading {
    @include responsiveProperty(height, 40vh, 40vh, 40vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }

  .el-card__header {
    // line-height: 1.4;
    @include responsiveProperty(padding-bottom, 0px, 5px, 10px);
  }

  .feedconsumption-card-count__header {
    display: flex;
    align-items: center;
    @include responsiveProperty(line-height, 1.8, 1.8, 1.8);

    .header-stats {
      font-size: 8px;
      background: #233a73;
      border-radius: 5px;
      display: flex;
      padding: 5px;
      color: white;

      .value {
        font-size: 13px;
      }
    }

    .weekly {
      background-color: #d39f00;
    }

    .daily {
      background-color: #155dbe;
    }

    .monthly {
      background-color: greenyellow;
    }
  }

  .el-card__body {
    display: flex;
    padding: 5px 10px 0px 10px !important;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    .highcharts_space {
      @include responsiveProperty(margin-bottom, 8.5px, 6.8px, 11.5px);
    }
  }

  .feed-stat {
    text-transform: capitalize;
    display: flex;
    align-items: center;

    &--dialy {
      color: #155dbe;
    }

    &--weekly {
      color: #d39f00;
    }

    &--monthly {
      color: greenyellow;
    }

    &__title {
      text-align: right;
      margin-right: 5px;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }

    &__value {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;

      .filter-popover-trigger {
        cursor: pointer;
      }

      p {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        margin-right: 4px;
        margin-top: auto;
      }

      img {
        @include icon-size(svg, suffix);
      }
    }
  }
}
</style>
