<template>
  <high-charts
    :options="selectedOptions"
    :key="`${selectedFeedPeriod}_${$i18n.locale}`"
    ref="highcharts"
    constructor-type="stockChart"
  ></high-charts>
</template>
<script>
import { feedConsumptionConfig } from "./utils/chartOptions";
import dateUtils from "@/utils/dateUtils";
import { mapGetters } from "vuex";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  props: {
    selectedFeedPeriod: {
      default: "daily",
      type: String
    },
    feedAreaSwitch: {
      type: Boolean
    },
    feedingMode: {
      type: String,
      default: "ALL"
    }
  },
  mixins: [
    datesHandlerMixin,
    resizeContainerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      feedPeriodList: {
        daily: {
          css_class: "feed-stat--daily",
          title: "daily",
          color: "#155DBE",
          date_units: "days",
          isSameComparator: "isSameDay",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_dark_bar_color: "#155DBE",
            highlight_light_bar_color: "#c3dceb",
            light_color: "#cbe6f6",
            dark_color: "#2D9CDB"
          }
        },
        weekly: {
          css_class: "feed-stat--weekly",
          title: "Comn_weekly",
          color: "#D39F00",
          date_units: "weeks",
          isSameComparator: "isSameISOWeek",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_dark_bar_color: "#D39F00",
            highlight_light_bar_color: "#e7dbb7",
            light_color: "#fcefc9",
            dark_color: "#F2C94C"
          }
        },
        monthly: {
          // period_type: "monthly",
          css_class: "feed-stat--monthly",
          title: "Comn_monthly",
          color: "#6FCF97",
          date_units: "months",
          isSameComparator: "isSameMonth",
          range_1: 1,
          range_2: 2,
          chartColors: {
            highlight_dark_bar_color: "#3e9f66",
            highlight_light_bar_color: "#bfd7c9",
            light_color: "#dbf3e5",
            dark_color: "#6FCF97"
          }
        }
      },
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      },
      feedModesList: [
        { mode: "ALL", lang_key: "Comn_all" },
        { mode: "BASIC", lang_key: "PM_sch_mode4" },
        { mode: "SCHEDULE", lang_key: "Comn_schedule" },
        { mode: "AUTOMATIC", lang_key: "PM_automatic" },
      ],
    }
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("dashboard", {
      getFeedConsumptionData: "getFeedConsumptionData"
    }),
    changedfeedAreaSwitchValue() {
      return this.feedAreaSwitch;
    },
    selectedOptions() {
      return this.changedfeedAreaSwitchValue ? this.graphFeedAreaOptions : this.graphOptions
    },
    getResponsiveChartHeight() {
      if (window.innerWidth >= 1920) {
        return "260px";
      } else if (window.innerWidth >= 1366) {
        return "230px";
      } else {
        return "200px";
      }
    },
    graphOptions() {
      const feedData = [];
      const dFeedData = [];
      // Chart configuration
      const getDispFeedPercent = (dispFeed, totalFeed) => {
        return Math.round((dispFeed * 100) / totalFeed);
      };
      const chOptions = this.$lodash.cloneDeep(feedConsumptionConfig.chart_options);
      chOptions.lang = {
        loading: this.$t("Comn_loading_pls_wait"),
        noData: this.$t("Comn_no_data_connection"),
      }
      chOptions.chart.height = this.getResponsiveChartHeight;
      const selectedFeedPeriod = this.selectedFeedPeriod;
      const feedConsuptionData = this.getFeedConsumptionData
      const dhmGetTime = this.dhm__dateUtilsLib.getTime
      const dhmParseISO = this.dhm__dateUtilsLib.parseISO
      chOptions.xAxis.tickPositioner = function() {
        const positions = [];
        feedConsuptionData.forEach(
        ({ date }) => {
          positions.push(dhmGetTime(
            dhmParseISO(date)
            ))
          })
          return positions;
    };
      chOptions.xAxis.tickAmount = this.selectedFeedPeriod === "daily" ? 10 : 5;
      chOptions.xAxis.startOfWeek = 0;
      chOptions.tooltip.xDateFormat = this.dateFormatObject[
        this.upm__getFormatDateString
      ];
      let isCurrent = false;
      // Creating two serie for the stacked column - percentage mode
      if (!this.getFeedConsumptionData) {
        return chOptions;
      }
      this.getFeedConsumptionData.forEach(
        ({ dispensed_feed, feed, date }, index) => {
          const objselecteddate = this.feedPeriodList[selectedFeedPeriod];
          isCurrent = this.dhm__dateUtilsLib[objselecteddate.isSameComparator](
            this.dhm__getTodayInUserTZ,
            this.dhm__dateUtilsLib.castBrowserDateToUserUTC(date, {
              timeZone: this.getUserTimeZoneString
            })
          );
          feedData[index] = {
            x: this.dhm__dateUtilsLib.getTime(
              this.dhm__dateUtilsLib.parseISO(date)
            ),
            y: feed - dispensed_feed,
            dateFormat: this.dhm__formatOnDateObjWithLocale(
              this.dhm__dateUtilsLib.parse(
                date,
                "yyyy-MM-dd'T'HH:mm:ss'Z'",
                new Date()
              ),
              this.upm__getFormatDateString
            ),
            feed_value: feed,
            color: isCurrent
              ? this.feedPeriodList[selectedFeedPeriod].chartColors
                  .highlight_light_bar_color
              : this.feedPeriodList[selectedFeedPeriod].chartColors
              .light_color,
            dispense_feed_percentage: getDispFeedPercent(dispensed_feed, feed),
            feedTrans: this.$t("Comn_feed"),
            dispensedFeedTrans: this.$t("PM_dispensed_feed"),
            units: this.getPreferredUnits.feed_units,
            value_type: "feed_limit",
            visible: dispensed_feed <= 0
          };
          dFeedData[index] = {
            x: this.dhm__dateUtilsLib.getTime(
              this.dhm__dateUtilsLib.parseISO(date)
            ),
            y: dispensed_feed,
            dateFormat: this.dhm__formatOnDateObjWithLocale(
              this.dhm__dateUtilsLib.parse(
                date,
                "yyyy-MM-dd'T'HH:mm:ss'Z'",
                new Date()
              ),
              this.upm__getFormatDateString
            ),
            feed_value: feed,
            color: isCurrent
              ? this.feedPeriodList[selectedFeedPeriod].chartColors
                  .highlight_dark_bar_color
              : this.feedPeriodList[selectedFeedPeriod].chartColors.dark_color,
            dispense_feed_percentage: getDispFeedPercent(dispensed_feed, feed),
            feedTrans: this.$t("Comn_feed"),
            dispensedFeedTrans: this.$t("PM_dispensed_feed"),
            units: this.getPreferredUnits.feed_units,
            value_type: "dispensed_feed",
            visible: dispensed_feed > 0
          };
        }
      );

      const series = [
        {
          name: this.$t("PM_day_feed"),
          data: feedData.some(obj => obj.y > 0) ? feedData : [],
          pointWidth: this.selectedFeedPeriod !== "daily" ? 50 : 20
        },
        {
          name: this.$t("PM_dispensed"),
          data: dFeedData.some(obj => obj.y > 0) ? dFeedData : [],
          pointWidth: this.selectedFeedPeriod !== "daily" ? 50 : 20
        }
      ];

      return {
        ...chOptions,
        series
      };
    },
    getFeedAreaKeyByUserPreferences() {
      const areaToKeyMap = {
        ac: "feed_per_acre",
        ha: "feed_per_hectare"
      };
      return areaToKeyMap[this.upm__getPondSizeUnits];
    },
    graphFeedAreaOptions() {
      const feedLimitByAreaData = [];
      // Chart configuration
      const chOptions = this.$lodash.cloneDeep(feedConsumptionConfig.chart_options_feed_per_area);
      chOptions.lang = {
        noData: this.$t("Comn_no_data_connection"),
        loading: this.$t("Comn_loading_pls_wait"),
      }
      chOptions.chart.height = this.getResponsiveChartHeight;
      const selectedFeedPeriod = this.selectedFeedPeriod;
      chOptions.xAxis.tickPositioner = function() {
        let positions = [];
        if (selectedFeedPeriod !== "monthly") {
          let tick = Math.floor(this.dataMin);
          const intervals = selectedFeedPeriod === "daily" ? 9 : 4;
          const increment = Math.ceil(
            (this.dataMax - this.dataMin) / intervals
          );

          if (this.dataMax !== null && this.dataMin !== null) {
            for (tick; tick - increment <= this.dataMax; tick += increment) {
              positions.push(tick);
            }
          }
        } else {
          if (this.dataMax !== null && this.dataMin !== null) {
            const resArr = [
              this.dataMin,
              dateUtils.addMonths(this.dataMin, 1).getTime(),
              dateUtils.addMonths(this.dataMin, 2).getTime(),
              dateUtils.addMonths(this.dataMin, 3).getTime(),
              dateUtils.addMonths(this.dataMin, 4).getTime()
            ];
            positions = resArr;
          }
        }
        return positions;
      };
      chOptions.xAxis.tickAmount = this.selectedFeedPeriod === "daily" ? 10 : 5;
      chOptions.xAxis.startOfWeek = 0;
      chOptions.tooltip.xDateFormat = this.dateFormatObject[
        this.upm__getFormatDateString
      ];
      let isCurrent = false;
      // Creating two serie for the stacked column - percentage mode
      if (!this.getFeedConsumptionData) {
        return {};
      }
      this.getFeedConsumptionData.forEach((dataObj, index) => {
        const objselecteddate = this.feedPeriodList[selectedFeedPeriod];
        isCurrent = this.dhm__dateUtilsLib[objselecteddate.isSameComparator](
          this.dhm__getTodayInUserTZ,
          this.dhm__dateUtilsLib.castBrowserDateToUserUTC(dataObj.date, {
            timeZone: this.getUserTimeZoneString
          })
        );
        feedLimitByAreaData[index] = {
          x: this.dhm__dateUtilsLib.getTime(
            this.dhm__dateUtilsLib.parseISO(dataObj.date)
          ),
          dateFormat: this.dhm__formatOnDateObjWithLocale(
            this.dhm__dateUtilsLib.parse(
              dataObj.date,
              "yyyy-MM-dd'T'HH:mm:ss'Z'",
              new Date()
            ),
            this.upm__getFormatDateString
          ),
          y: dataObj[this.getFeedAreaKeyByUserPreferences],
          label: this.$t("feed-area"),
          color: isCurrent
              ? this.feedPeriodList[selectedFeedPeriod].chartColors
                  .highlight_dark_bar_color
              : this.feedPeriodList[selectedFeedPeriod].chartColors.dark_color,
          dispensedFeedTrans: this.$t("PM_dispensed_feed"),
          units: this.getPreferredUnits.feed_units
        };
      });
      const series = [
        {
          name: this.$t("PM_dispensed"),
          data: feedLimitByAreaData.some(obj => obj.y > 0) ? feedLimitByAreaData : []
        }
      ];

      return {
        ...chOptions,
        series
      };
    },
  }
}
</script>
<style lang="scss">
  // highchart styling
  $stroke-color: $highcharts-axis-stroke-color;
  .highcharts-axis-title {
    fill: $highcharts-axis-title-color !important;
    @include highcharts-axis-title-font-size;
  }
  .highcharts-xaxis {
    .highcharts-axis-line {
      stroke: $stroke-color;
    }
    .highcharts-tick {
      stroke: $stroke-color;
    }
  }

  .highcharts-xaxis-labels {
    &.highcharts-axis-labels text {
      color: $stroke-color !important;
      @include highcharts-axis-labels-font-size;
      fill: $stroke-color !important;
    }
  }
  .highcharts-yaxis-labels {
    &.highcharts-axis-labels text {
      color: $stroke-color !important;
      @include highcharts-yaxis-labels-font-size;
      fill: $stroke-color !important;
    }
  }
  .highcharts-tooltip {
    span {
      @include highcharts-tooltip-font-size;
    }
  }
  // charts scroll bar styling
  .highcharts-scrollbar-thumb {
    fill: $stroke-color;
    stroke: $stroke-color;
    opacity: 0.5;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-button {
    fill: #ffffff;
    stroke: #00000066;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-arrow {
    fill: $stroke-color;
  }

  .highcharts-scrollbar-rifles {
    stroke: #ffffff;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #00000066;
    stroke-width: 1px;
  }
</style>
